import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getAnalytics } from "firebase/analytics"
import { getFunctions } from "firebase/functions"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "@firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyA-qbmaRzzicR5PTFLE0UL8gleBZi-ATxo",
  authDomain: "analitica-cb117.firebaseapp.com",
  databaseURL: "https://analitica-cb117.firebaseio.com",
  projectId: "analitica-cb117",
  storageBucket: "analitica-cb117.appspot.com",
  messagingSenderId: "378311496768",
  appId: "1:378311496768:web:e0f89b4a9ffb5040e6fba5",
  measurementId: "G-VG9E3C43FQ"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

const auth = getAuth(app)
const analytics = getAnalytics(app)
const functions = getFunctions(app)
const firestore = getFirestore(app)
const storage = getStorage(app)

export {
  app,
  
  auth,
  analytics,
  functions,
  firestore,
  storage,
}