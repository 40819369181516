import { createRouter, createWebHistory } from 'vue-router'
import { getCurrentUser } from '@/helpers/auth'

const routes = [
  // {
  //   path: '/purchase',
  //   name: 'purchase',
  //   component: () => import('@/views/PurchaseView.vue'),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/form',
  //   name: 'form',
  //   component: () => import('@/views/FormView.vue'),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/response/:fid',
  //   name: 'response',
  //   component: () => import('@/views/ResponseView.vue'),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/LoginView.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    meta: {
      requiresAuth: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const user = await getCurrentUser()
  if (to.meta.requiresAuth && !user) {
    next('auth');
  } else {
    next();
  }
})

export default router
