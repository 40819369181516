import 'bootstrap/dist/css/bootstrap.min.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// import axios from 'axios'
// import VueAxios from 'vue-axios'

createApp(App)
  .use(store)
  .use(router)
  .use(VueSweetalert2)
  // .use(VueAxios, axios)
  // .provide('axios', app.config.globalProperties.axios)
  .mount('#app')


import 'bootstrap/dist/js/bootstrap.js'
